import logo from './logo.svg';
import './App.css';

import { ConfigProvider, theme, App, } from "antd";
import AccountDeletionForm from './account_deletion_page';
import { NotificationProvider } from './context/notification-context';

function MyApp() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#17123b",
        },
      }}
    >   <App>
        <NotificationProvider>
          <AccountDeletionForm />
        </NotificationProvider>
      </App>
    </ConfigProvider>
  );
}

export default MyApp;

import { Form, Button, Input, } from 'antd';
import './styles/login.css'
import { Modal, Result } from 'antd';
import { useState } from 'react';
import { verifyOTP } from "./api/auth/auth";
import { useNotification } from './context/notification-context'
const OTP = () => {


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleted, setIsDeleted] = useState(null); // null = no action, true = success, false = error

  // Simulate an account deletion action (could be replaced by an API call)


  const [isLoading, setIsLoading] = useState(false);
  const triggerNotification = useNotification();

  const handleOk = () => {
    setIsModalVisible(false);
    setIsDeleted(null); // Reset the modal state after closing
  };


  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  const onChange = (text) => {
    console.log('onChange:', text);
    if (text.length == 6) {
      onFinishResolve(text);
    }
  };

  const sharedProps = {
    onChange,
  };




  const onFinish = values => {
    console.log('Success:', values);
    if (values.otp.length == 6) {

      onFinishResolve(values.otp);
    }

  };


  const onFinishResolve = async (otp) => {
    try {
      setIsLoading(true);
      const dataToPost = {
        // reason: formData.email,
        otp,
      };
      const result = await verifyOTP(dataToPost);

      if (result.status) {

        triggerNotification({
          type: "success",
          message: "Success",
          description: "Account Deleted successfully.",
        });

        // const success = Math.random() > 0.5; // Randomly simulate success or error for demo purposes
        setIsDeleted(true);
        setIsModalVisible(true);

      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message || "Failed to delete account. Please try again later.",
        });
        setIsDeleted(false);
        setIsModalVisible(true);
      }
    } catch (error) {

      triggerNotification({
        type: "error",
        message: "Login Failed",
        description:
          error?.toString() || "An error occurred. Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="login-box">

          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <p className="form-title">OTP to Verify</p>
            <Form.Item
              name="otp"
              rules={[{ required: true, message: 'Please input OTP!' }]}
            >
              <Input.OTP length={6} {...sharedProps} />
            </Form.Item>




            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                VERIFY
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
        centered
      >
        {isDeleted ? (
          <Result
            status="success"
            title="Account Deleted"
            subTitle="Your account has been successfully deleted."
          />
        ) : (
          <Result
            status="error"
            title="Account Deletion Failed"
            subTitle="An error occurred while trying to delete your account. Please try again later."
          />
        )}
      </Modal>
    </>
  );
};

export default OTP;
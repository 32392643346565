import React, { useState } from "react";
import { Layout, Steps, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import 'antd/dist/reset.css'; // Ant Design styles
import Login from "./login";
import OTP from "./Otp";
import Reason from "./reason";




const { Header, Content } = Layout;
const { Step } = Steps;

const AccountDeletionForm = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
        <Layout>
            {/* Sticky Header */}
            <Header style={{ position: 'sticky', top: 0, paddingBottom: '90px', zIndex: 1000, width: '100%', backgroundColor: '#17123b' }}>
                <div className="logo" style={{ float: 'left', color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
                    <img src={require('./assets/SVGs/trava-logo-white.svg').default} alt="Logo" style={{ width: '100px' }} />

                </div>
                <nav style={{ float: 'right' }}>
                    <a href="https://travaapp.com" style={{ color: 'white', fontSize: '18px', textDecoration: 'none' }}>
                        Learn More
                    </a>
                </nav>
            </Header>

            {/* Content Section */}
            <Content style={{ padding: '20px' }}>
                <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>
                    <h1>User Account Delete Form</h1>
                    {/* Steps Component */}
                    <Steps current={currentStep} style={{ marginBottom: '40px', color: '#1DA57A', }}>
                        <Step title="Provide reason for account deletion." />
                        <Step title="Enter email and password." icon={<UserOutlined />} />
                        <Step title="Verify OTP." />

                    </Steps>
                    <div style={{ marginTop: '40px' }}>

                        {currentStep === 0 && <div><Reason onClick={nextStep} /></div>}
                        {currentStep === 1 && <div><Login onNext={nextStep} onPrevious={prevStep} /></div>}
                        {currentStep === 2 && <div><OTP /></div>}


                    </div>
                </div>
            </Content>
        </Layout>
    );
};

export default AccountDeletionForm;

// Retrieve sessionData from localStorage
const getSessionData = () => {
  return JSON.parse(localStorage.getItem("sessionData")) || {};
};

// Clear sessionData from localStorage
export const clearSessionData = () => {
  localStorage.removeItem("sessionData");
};

export const getUserId = () => {
  const sessionData = getSessionData();
  return sessionData.emailAddress || null;
};

// Set sessionData back to localStorage
const setSessionData = (newSessionData) => {
  localStorage.setItem("sessionData", JSON.stringify(newSessionData));
};

// Get accessToken
const getAccessToken = () => {
  const sessionData = getSessionData();
  return sessionData.accessToken || null;
};

// Set accessToken
const setAccessToken = (accessToken) => {
  const sessionData = getSessionData();
  const newSessionData = {
    ...sessionData,
    accessToken,
  };
  setSessionData(newSessionData);
};


export { getAccessToken, setAccessToken, };

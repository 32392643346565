import React from 'react';
import { Form, Button, Input, Checkbox } from 'antd';
import './styles/delete-account.css'; // Make sure to update the stylesheet accordingly

const Reason = ({onClick}) => {
  const onFinish = (values) => {
    console.log('Form Submitted:', values);
    onClick();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Form Submission Failed:', errorInfo);
  };

  return (
    <div className="delete-account-page">
      <div className="delete-account-box">
        <Form
          name="delete-account-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">We’re sorry to see you go!</p>

          <p>Please tell us why you're deleting your account:</p>
          
          {/* Reason for deleting (Multiple choice) */}
          <Form.Item
            name="reasons"
            rules={[{ required: true, message: 'Please select at least one reason!' }]}
          >
            <Checkbox.Group>
              <Checkbox value="privacy">Concern about privacy</Checkbox>
              <Checkbox value="notUseful">I don't find the app useful</Checkbox>
              <Checkbox value="tooManyEmails">I receive too many emails</Checkbox>
              <Checkbox value="other">Other</Checkbox>
            </Checkbox.Group>
          </Form.Item>

          {/* Additional feedback */}
          <Form.Item
            name="additionalFeedback"
            rules={[{ required: false }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Any other feedback you'd like to share?"
            />
          </Form.Item>

          {/* Confirm and Submit */}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="delete-form-button">
              <span>Submit</span>
              <span className="inline-block w-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0-7.5 7.5M21 12H3"></path>
                </svg>
              </span>
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Reason;

import { Form, Button, Input } from 'antd';
import './styles/login.css'

import { useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import { useNotification } from './context/notification-context'

import { login } from "./api/auth/auth";
import { setAccessToken } from './api/requests/localStorageService';

const Login = ({ onNext, onPrevious }) => {


  const [form] = Form.useForm();


  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const triggerNotification = useNotification();
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = async () => {
    try {
      setIsLoading(true);
      const dataToPost = {
        email: formData.email,
        password: formData.password,
      };
      const result = await login(dataToPost);
      console.log("result.tokvdsen ", result)
      if (result.status) {

        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "Login successful.",
        });
        setAccessToken(result.token);
        // const loginContext = JSON.stringify({
        //   userId: dataToPost.userId,
        //   emailAddress: formData.email,
        //   ...result.data,
        // });

        onNext();
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message || "Failed to login. f Please try again later.",
        });
      }
    } catch (error) {

      triggerNotification({
        type: "error",
        message: "Login Failed",
        description:
          error?.toString() || "An error occurred. Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="login-page">
      <div className="login-box">

        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              placeholder="Email Address"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              placeholder="Password"
              iconRender={(visible) =>
                visible ? (
                  <EyeOutlined style={{ color: "#E5791B" }} />
                ) : (
                  <EyeInvisibleOutlined />
                )
              }
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
          </Form.Item>


          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              <span>LOGIN</span>
              <span class="inline-block w-6"><svg xmlns="http://www.w3.org/2000/svg" width="16" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"></path></svg></span>
            </Button>
          </Form.Item>
          <Button type="text" onClick={onPrevious} className="prev-form-button">
            <span>Previous</span>

          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
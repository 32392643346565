import axios from "axios";
import {
  getAccessToken,
  setAccessToken,
  clearSessionData,
} from "./localStorageService";

const axiosInstance = axios.create({
  baseURL: "https://api.travaapp.com/delete",
  headers: { "Content-Type": "application/json" }, //optional: default headers
});

// set up request interceptor
// if there is an access token, set the Authorization header with the token
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    console.log("getAccessToken ", getAccessToken);
    if (accessToken) {
      config.headers.trava_access_token = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// export the axios instance
export default axiosInstance;

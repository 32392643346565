import { postRequest, postAuthenticatedRequest } from "../requests/request";

// Function to preregister a user
export const login = async ({ email, password }) => {
  try {
    const endpoint = "/login";
    const data = { email, password };

    const response = await postRequest(endpoint, data);

    return response.data;

  } catch (error) {
    console.error("LOGIN Error fetching policies:", error);
    return error.response.data ?? error;
  }
};


export const verifyOTP = async (otp, reason) => {
  try {
    const endpoint = "/verify2FAOTP";
    const data = otp;

    const response = await postAuthenticatedRequest(endpoint, data);

    return response.data;

  } catch (error) {
    console.error("Error fetching policies:", error);
    return error.response.data ?? error
  }
};

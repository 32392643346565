import React, { createContext, useContext, useCallback } from "react";
import { App } from "antd";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { notification } = App.useApp();

  const triggerNotification = useCallback(
    ({ type, message, description }) => {
      // Check for valid notification type and call the appropriate method
      if (type && notification[type]) {
        console.log("that???? ", type);
        notification[type]({
          message,
          description,
        });
      } else {
        console.log("this????");
        // Handle invalid type or fallback to default notification
        notification.open({
          message: message || "Notification",
          description: description || "No description provided",
        });
      }
    },
    [notification]
  );

  return (
    <NotificationContext.Provider value={triggerNotification}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);

import axios from "axios";
import axiosInstance from "./axiosInstance";

const baseUrl = "https://api.travaapp.com/delete";

// Function to make a post request
export const postRequest = async (endpoint, data) => {
  const dataToSend = data;

  try {
    const response = await axios.post(`${baseUrl}${endpoint}`, dataToSend);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Function to make an authenticated post request
export const postAuthenticatedRequest = async (endpoint, data) => {
  const dataToSend = JSON.stringify(data);

  try {
    const response = await axiosInstance.post(endpoint, dataToSend);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
